import { type ReactNode, useState } from "react";
import { useTheme } from "styled-components";
import {
  Footer,
  Link,
  type ThemeType,
  IconFa,
} from "@cruk/cruk-react-components";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { TopSection } from "@fwa/src/components/TopSection";

import {
  PageContentWrapper,
  StyledUL,
  StyledLi,
  StyledButton,
  ChevronWrapper,
  TermsContent,
  FooterWrapper,
} from "./styles";

type LayoutProps = {
  children: ReactNode;
  path: string;
};

export const Layout = ({ children, path }: LayoutProps) => {
  const fullBleedRoutes = ["/", "/fundraiser/dashboard"];
  const [showLinks, setShowLinks] = useState(false);
  const isFullBleed = fullBleedRoutes.includes(path);
  const theme = useTheme();
  const typedTheme = theme as ThemeType;

  const handleClick = () => {
    setShowLinks(!showLinks);
  };

  return (
    <>
      <TopSection />
      <main>
        <PageContentWrapper id="main" $isFullBleed={isFullBleed}>
          {children}
        </PageContentWrapper>
      </main>
      <FooterWrapper>
        <Footer>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/about-us/contact-us"
          >
            Contact us
          </Link>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/get-involved/do-your-own-fundraising/practical-fundraising-help/set-up-a-cancer-research-uk-giving-page/fees-and-charges"
          >
            Fees and charges
          </Link>
          <StyledButton appearance="tertiary" onClick={handleClick}>
            <TermsContent>
              Terms & Conditions
              <ChevronWrapper $active={showLinks}>
                <IconFa
                  faIcon={faChevronDown}
                  size="1em"
                  color={typedTheme.colors.linkColor}
                />
              </ChevronWrapper>
            </TermsContent>
          </StyledButton>
          {showLinks ? (
            <StyledUL>
              <StyledLi>
                <Link
                  appearance="primary"
                  href="https://www.cancerresearchuk.org/terms-and-conditions"
                >
                  Cancer Research UK
                </Link>
              </StyledLi>
              <StyledLi>
                <Link
                  appearance="primary"
                  href="https://www.standuptocancer.org.uk/information/terms-and-conditions"
                >
                  Stand Up o Cancer
                </Link>
              </StyledLi>
            </StyledUL>
          ) : null}
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/privacy-statement"
          >
            Privacy
          </Link>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/terms-and-conditions/cookies-policy"
          >
            Cookies
          </Link>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/accessibility"
          >
            Accessibility
          </Link>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/cancer-research-uk-giving-pages-help"
          >
            Help
          </Link>
        </Footer>
      </FooterWrapper>
    </>
  );
};

export default Layout;
